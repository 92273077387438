import React from 'react'
import { AppWrap, MotionWrap } from '../../wrapper';
import { images } from '../../constants'

import './Mindmap.scss';

const Mindmap = () => {
  return (
    <>
        <h2 className="head-text">My Academic <span>Education</span></h2>
        <img className="img-mind"src={images.mymindmap} alt="mindmap" unselectable="on"></img>
    </>

  )
}

export default AppWrap(
  MotionWrap(Mindmap, 'app__skills'),
  'mindmap',
  'app__whitebg',
);