import React from 'react'
import { AppWrap, MotionWrap } from '../../wrapper';
import logokh from '../../assets/logoKH.png';
import lep from '../../assets/LEP.pdf';

import './Testimonial.scss';

const Testimonial = () => {
  return (
    <>
      <h2 className="head-text">My <span>Volunteer commitment</span></h2>
      <div class="parentss">
        <div class="div1ss">
          <p>
            As part of the <b>Personal and Professional Project</b>, we have the opportunity to create a Personal Commitment Booklet (LEP).
            Indeed, since my fourth year (2021-2022) at ESAIP, I have been committed to an organization whose goal is to improve the
            living conditions of hospitalized children: <b>Kids' Hope.</b><br></br><br></br>
            Kids' Hope is a <b>non-profit organization</b>. Its goal is to improve the living conditions of hospitalized children by supporting them
            morally through visits, our listening and our support, in partnership with trained professionals, but also
            professionals, but also by making society aware of this cause. Kids' Hope today has <b>55 members</b>.
          </p><br></br>
          <p>
            I am currently part of the <b>IT department</b> of the organization, where my main mission is to
            to update and redesign the Kids' Hope website.<br></br><br></br>
            I first started by making a study of the old website, which allowed me to understand
            structure, to detect the problems already present, and to have an idea of how to
            design the new one.
            Several models and examples were then realized to better define the project. Indeed, I was able to
            learn how to design first mock-ups to then start the realization of the website, which
            website, which took 5 months to complete.<br></br><br></br>
            Feedbacks from the heads of division all along the period allowed me to improve the state of the website
            the state of the website, to arrive at a result validated by the team.
            Currently, I am still in charge of its improvement.
          </p>
          <a href={lep} target="_blank" download="LEP_ismailKabbaj.pdf">
            <button type="button" className="p-text">See more in french</button>
          </a>
        </div>
        <div class="div2ss">
          <img className="img-mind" src={logokh} alt="mindmap" unselectable="on"></img>
        </div>
      </div>
    </>

  )
}

export default AppWrap(
  MotionWrap(Testimonial, 'app__skills'),
  'testimonial',
  'app__primarybg',
);